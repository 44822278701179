import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "gatsby";

// import Layout from "../components/layout"
// import SEO from "../components/seo"
// import VoucherAd from "../components/voucherAd";§
// import BackButton from "../components/backButton"
import SEO from "../components/seo";
import TopBar from "../components/Layout/TopBar";
import Navbar from "../components/Layout/Navbar";
import Footer from "../components/Layout/Footer";
import Seo from "../components/seo";
import Navigation from "../components/Layout/Navigation";
import DesktopMenu from "../components/Layout/Navigation/DesktopMenu";
import MenuControls from "../components/Layout/Navigation/MenuControls";
import MobileNavigation from "../components/Layout/Navigation/MobileNavigation";
import ReusableTiles from "../components/ReusableTiles";
import { css } from "@emotion/core";

// import "../scss/main.scss";

const TermsAndConditions = () => (
  // <Layout>
  <div>
    {/* <SEO title="Terms and Conditions" keywords={[`great`, `british`, `dogs`]} /> */}
    <SEO
      title="greatbritishdogs.co.uk | Terms and Conditions"
      keywords={[`great`, `british`, `dogs`]}
    />
    <TopBar></TopBar>
      <Navigation>
        <MenuControls />
        <MobileNavigation></MobileNavigation>
      </Navigation>
      <DesktopMenu />

    {/* <BackButton page="form" link="share-your-story"></BackButton> */}
    <div className="py-12 prose max-w-2xl mx-auto">
      <div className="container text-lightCopy">
      <div className="text-center">
              <div className="font-bench  text-white text-xl mx-auto bg-article px-4 py-1 uppercase leading-none inline-block rounded-lg my-3" css={css`
                background-color: #004030 !important;`}>
                Article
              </div>
            </div>
        <div className="form-title">
          <h1 css={css`
                color: #ae456c !important; margin: 0.5em auto 0.5em auto !important; font-size: 2rem !important; text-align: center !important; padding: 0 !important;
                `} className="font-slab">Terms and Conditions</h1>

          <h2 className="font-sans font-bold text-xl text-primary" css={css`
                margin-bottom: 1em !important; margin-top: 1em !important; font-size: 1.1em !important;`}>
            {" "}
            Great British Dogs 2024 Competition
          </h2>
        </div>

        <p className="my-4">
        For the chance to win a stay at the dog-friendly Double Red Duke Hotel in the Cotswolds village of Clanfield and a hamper of dog products, tell us about your dog and the positive influence it has in your life.
        </p>
        <p className="my-4">
        The Promoter of the competition is Immediate Media Company London Limited.
        </p>
        <p className="my-4">
        The competition is open to all residents of the UK, including the Channel Islands, aged 18 years or older, except the Promoter’s employees or their direct family members, or anyone connected with the competition or the administration of the competition.
        </p>
        <p className="my-4">
        The competition will run from <strong>21 May 2024 until 18 November 2024, 23.59pm</strong>. Entries received after the closing date of the competition will not be considered.
        </p>
        <p className="my-4">By entering the competition, you agree:</p>

        <ul className="list-disc" css={css`list-style-type: none !important;`}>
          <li>to be bound by these terms and conditions; and</li>
          <li>you will automatically be entered in the{" "}
          <span className="font-bold">'Dog of the Month'</span> competition,
          details of which can be found {" "}<a className="text-primary font-bold" href="https://www.greatbritishdogs.co.uk/submit-story-2024">here</a>{" "}, and you agree to be bound by the respective terms and conditions outlined on this page.</li>
        </ul>

        {/* <span className="opt-in-disclaimer">In accordance with the Dog of the Month competition <Link to={"/terms-and-conditions"}>Terms and Conditions.</Link></span> */}

        <h2 className="font-bold font-sans text-xl text-primary ">How to enter</h2>
        <p className="my-4">
          To enter the competition, complete and submit the form on {" "}
          <a className="font-bold text-primary" href="https://www.greatbritishdogs.co.uk/submit-story-2024">www.greatbritishdogs.co.uk</a>{" "} (the “Website”), including your email, name, dog’s details and your story about the positive influence your dog has made in your life. You must submit a photo of your dog as part of your entry, but please ensure you have the consent of any individuals who are featured in the photo with the dog. You also have the option to upload a video as part of your entry.
        </p>
        <p className="my-4">
        Eligible entries will be judged by a panel of judges who will select a shortlist of dogs to progress to a public vote (the “Public Vote”). The decision of the judges (acting reasonably) will be final.
        </p>
        <p className="my-4">Entries will be judged by the panel and shortlisted on the basis of:</p>
        <ul className="list-disc" css={css`list-style-type: none !important;`}>
          <li>the positive impact the dog has had on your life; and</li>
          <li>any unique or special qualities of the relationship between you and your dog.</li>
        </ul>
        <p className="my-4">
        The shortlisted entries will progress to a public vote, starting on 4 December 2024 and closing at 23:59 on 31st December 2024. The dog which receives the most public votes will be awarded Dog of the Year 2024.
        </p>
        <p className="my-4">
        There is a limit of one (1) entry per person and entries on behalf of another person will not be accepted. Joint submissions are not allowed.
        </p>
        <a id="form"></a>
        <p className="my-4">
        The Promoter reserves all rights to disqualify you if your story is found to be false, or if your conduct is contrary to the spirit or intention of the competition. The Promoter will not enter into any correspondence relating to the competition.
        </p>
        <h2 className="font-bold font-sans text-xl text-primary" css={css`margin-top: 1.2em; !important;`}>The Prizes</h2>
        <p className="my-4">See below for details of the monthly 'Dog of the Month' competition... The overall winner will receive:</p>
        <ul className="list-disc" css={css`list-style-type: none !important;`}>
          <li>a gift voucher worth £1,000 for <a className="font-bold text-primary" href="https://bit.ly/3UICIjD">The Double Red Duke Hotel</a> in Clanfield, Oxfordshire; and</li>
          <li>a hamper of goodies for your dog including Winalot dog food pouches.</li>
        </ul>
        <p className="my-4">
        There is no cash alternative and the prize is not transferable. Prizes must be taken as stated and cannot be deferred. The Promoter reserves the right to substitute the prize with one of the same or greater value.
        </p>
        <p className="my-4">
        The winner will be notified within 28 days of the close of the competition by email. If the winner cannot be contacted, or fails to respond within 28 days of such notification being sent, the Promoter reserves the right to offer the prize to another eligible entrant.
        </p>
        <p className="my-4">
        The Promoter will send the winner the voucher by email and the winner will be responsible for booking their stay at The Double Red Duke Hotel directly.  The validity period for the voucher will be stated on the voucher.  The voucher must be used on one visit only.  Lost or stolen vouchers will not be replaced.  No additional travel costs or expenses are included in the prize. 
        </p>
        <p className="my-4">
        The Double Red Duke Hotel is located in Clanfield, Oxfordshire, UK.  There are a limited number of dog-friendly bedrooms available, and a charge of £25 per dog, per night.  Dogs are permitted in the bar area of the Hotel not in the dining rooms or at the Chef’s Counter.  To bring your dog, you must contact the Double Red Duke Hotel in advance to ensure that a suitable room and/or table is allocated. Booking is subject to availability and subject to the terms and conditions applicable at the time of the booking.  For more information, please visit <a className="font-bold text-primary" href="https://www.countrycreatures.com/double-red-duke/faqs/">https://www.countrycreatures.com/double-red-duke/faqs/</a>.
        </p>
        <h2 className="font-bold font-sans text-xl text-primary" css={css`margin-top: 1.2em; !important;`}>
          Dog of the Month Competition
        </h2>
        <p className="my-4">
        All entries to the Great British Dogs Competition will automatically be entered into the Dog of the Month Competition, where one (1) entry each month will be selected at random to win a hamper of dog products.
        </p>
        <p className="my-4">
        Each 30-day period of the Great British Dogs Competition (each, a “Competition Month”) a winner will be selected at random from all the eligible entries received by the Promoter in the respective Competition Month. The winner of the Dog of the Month Competition shall be notified within 7 days of the close of the Competition Month.
        </p>
        <p className="my-4">
        The prize will consist of a hamper of dog products including pouches of Winalot dog food. There is no cash alternative and the prize will not be transferable. The Promoter reserves the right to substitute the prize with one of the same or greater value.
        </p>
        <p className="my-4">
        The Promoter may share the winner’s details with the prize provider for the purpose of fulfilling the prize. The prize must be claimed by the winner within 28 days from the date of the Promoter’s initial notification.
        </p>

        <h2 className="font-bold font-sans text-xl text-primary" css={css`margin-top: 1.2em; !important;`}>
          Ownership of Entries and Intellectual Property Rights
        </h2>
        <p className="my-4">
        The Promoter does not claim any rights of ownership in your competition entry.
        </p>
        <p className="my-4">
        The Promoter may wish to make your entry available to view on the Website, or as part of promotional materials for the 2024 Great British Dogs competition.  The Promoter has partnered with the ‘Pubity Pets’ on Instagram and TikTok for additional promotion of this year’s competition, and your entry may be selected to be shared on the Pubity Pets social media accounts.
        </p>
        <p className="my-4">By submitting your competition entry and any photographs and/or videos (“Your Content”) you:</p>
        <ul className="list-disc" css={css`list-style-type: none !important;`}>
          <li>grant the Promoter, its agents and partners (including without limitation Pubity Group Limited) a non-exclusive, worldwide, irrevocable licence, for the full period of any intellectual property rights in Your Content, to use, display, publish, transmit, copy, edit, alter, store, re-format and sub-licence Your Content for any purpose in connection with the publicity of the competition and/or the Website including on social media; and</li>
          <li>agree that you have the consent from any identifiable person featured in Your Content to appear in your entry and for the purposes set out above.</li>
        </ul>
        <p className="my-4">
        The Promoter is under no obligation to feature your entry on the Website or in any other format.
        </p>

        <h2 className="font-bold font-sans text-xl text-primary " css={css`margin-top: 1.2em; !important;`}>
          Data Protection and Publicity
        </h2>
        <p className="my-4">
        If your entry contains information relating to other individuals (e.g. family members, friends), you agree to confirm with those individuals that their details and/or image (as applicable) will be used by the Promoter (and Pubity Group Limited) for the purposes set out in these Terms and Conditions. You should avoid submitting entries containing images of individuals under the age of 18. If you choose to do so, you agree to obtain the consent of the child’s parent or guardian to use the child’s data in this way before submitting your entry.
        </p>
        <p className="my-4">If your entry is shortlisted for the public vote, you may be invited to participate in additional content creation opportunities with the Promoter, its agents and partners (including without limitation Pubity Group Limited).  Your participation is entirely voluntary.</p>
        <p className="my-4">
        If you win, you agree that your entry (including your name and (if featured in the photo) likeness) may be used by the Promoter for pre-arranged promotional purposes.
        </p>
        <p className="my-4">
        The Promoter will process your personal details (and the personal details relating to any other individual(s) featured in your entry) in accordance with the {" "}<a className="font-bold text-primary" href="https://www.immediate.co.uk/privacy/">Immediate Privacy Policy</a>{" "}.
        </p>
        <p className="my-4">
        The Promoter may share certain information relating to you with Nestlé Purina Petcare UK&I, including information required for marketing purposes where you have indicated you are happy to hear from them. Nestle Purina will process this data in accordance with their {" "}<a className="font-bold text-primary" href="https://www.purina.co.uk/privacy">Privacy Policy</a>{" "}.
        </p>

        <h2 className="font-bold font-sans text-xl text-primary " css={css`margin-top: 1.2em; !important;`}>General</h2>
        <p className="my-4">
        The Promoter reserves the right to amend these terms and conditions or to cancel, alter or amend the competition at any stage, if deemed necessary in its opinion, or if circumstances arise outside of its control.
        </p>
        <p className="my-4">
        The Promoter does not accept any responsibility for lost or fraudulent entries.
        </p>
        <p className="my-4">
        The Promoter excludes liability to the full extent permitted by law for any loss, damage or injury occurring to the participant arising from his or her entry into the promotion or occurring to the winner arising from his or her acceptance of a prize.
        </p>
        <p className="my-4">
        These terms and conditions shall be governed by English law and the parties submit to the exclusive jurisdiction of the course of England and Wales.
        </p><br />
      </div>
    </div>
    <div className="bg-pale-100">
        <div className="container" css={css`padding-right:0; padding-left:0;`}>
          <ReusableTiles />
        </div>
      </div>
    <Footer></Footer>
  </div>

  // </Layout>
);

export default TermsAndConditions;
